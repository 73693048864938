@import "../../style.scss";

.footer {
    background: $bgAccent;
    margin-top: 15vh;

    .container {
        color: $bg;
        gap: 50px;
        padding: 50px 5% 5vw;

        .top {
            display: flex;
            flex-direction: column;
            gap: 50px;

            .logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                color: inherit;
                transition: 0.2s ease-in-out;
                text-transform: inherit;
                text-align: center;

                img {
                    height: 100px;
                }

                h2 {
                    color: inherit;
                    height: auto;
                    transition: 0.2s ease-in-out;
                }

                span {
                    display: flex;
                    transition: 0.2s ease-in-out;
                    color: $accent;
                }
            }

            .contact {
                display: flex;
                justify-content: space-evenly;
                gap: 20px;

                @include mobile {
                    flex-direction: column;
                    align-items: center;
                }

                h2 {
                    margin-bottom: 30px;
                }

                a {
                    display: flex;
                    max-width: 250px;
                    margin-bottom: 10px;
                    color: $bg;
                    text-transform: initial;

                    svg {
                        color: $accent;
                        margin-right: 10px;
                    }
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            gap: 50px;

            @include mobile {
                flex-direction: column;
                align-items: center;
                gap: 10px;
            }

            .right {
                display: flex;
                gap: 10px;

                a {
                    text-transform: initial;
                }
            }
        }
    }
}