@import "../../style.scss";
@import "../../wp-style.scss";

.ferienwohnung {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: inherit;

    .topBar {
        display: flex;
        height: 100px;
        width: 90vw;
        max-width: 1920px;

        svg {
            height: inherit;
            width: 30px;
            fill: $accent;
            cursor: pointer;
        }
    }

    iframe {
        width: inherit;

        html {
            overflow: hidden;
        }
    }
}