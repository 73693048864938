@import "../../style.scss";

.maintenance {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: center;
        height: 200px;
        margin-top: 50px;
        margin-bottom: 50px;

        img {
            width: fit-content;
            height: 200px;
        }
    }

    .container {

        img {
            width: 100%;
            max-height: 400px;
            object-fit: cover;
        }

        .content {
            padding: 50px 0;
            text-align: center;

            p {
                margin-top: 20px;
            }
        }
    }
}