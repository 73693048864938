@import "../../style.scss";

.notfound {
    display: flex;
    flex-direction: column;

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;

        h2 {
            font-size: 40px;
        }

        h3 {
            font-size: 30px;
        }

        h4 {
            font-size: 25px;
            opacity: 0.75;
        }
    }
}