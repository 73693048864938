@import "../../style.scss";

.kontakt {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .gastgeber {
        display: flex;
        gap: 20px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

        .right {
            display: flex;
            flex-direction: column;

            .links {
                display: flex;
                margin-top: 20px;
                gap: 10px;

                @include mobile {
                    flex-direction: column;
                }
            }
        }
    }
}