$textColor: #191919;
$bg: #ffffff;
$accent: #146d4d;
$textColorHover: #878787;
$accentColorHover: #08573a;
$bgAccent: #31373F;
$bgLight: #e9e9e9;
$bgBox: #f5f2f2;

body {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 1.125rem;
  color: $textColor;
}

p {
  white-space: pre-line;
  line-height: 180%;
}

h1 {
  font-family: "Playfair Display", serif;
  font-size: calc(1.88392857rem + 1.51785714vw);
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: calc(1.55021008rem + 1.12394958vw);
}

h3 {
  font-size: calc(1.1164916rem + 0.73004202vw);
  color: $accent;
  text-transform: uppercase;
  font-weight: normal;
}

@media (min-width: 1400px) {
  body {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 3.375rem;
  }

  h2 {
    font-size: 2.05rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

a {
  color: $accent;
  text-decoration: none;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $accent;
  opacity: 1;
}

button {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  background-color: $accent;
  font-size: 1.2rem;
  border: none;
  padding: 15px 30px;
  color: currentColor;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background: $accentColorHover;
  }
}

.site {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  min-height: 100vh;
  color: $textColor;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90vw;
  max-width: 1920px;
}

.header {
  margin-top: 200px;

  .container {
    display: flex;
    justify-content: center;
    height: 50vh;

    h1 {
      text-align: center;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;

  &::before {
    content: "";
    border-radius: 50%;
    border: 10px solid rgba($textColor, 0.1);
    border-top: 10px solid $accent;
    height: 50px;
    width: 50px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin mobile {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 650px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 960px) {
    @content;
  }
}