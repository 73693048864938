@import "../../style.scss";

.anfragen {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .calendar {

        .container {
            background: $bgBox;
            align-items: center;
            gap: 30px;

            .summary {
                display: flex;
                justify-content: space-between;
                width: -webkit-fill-available;
                padding: 5vh;
                padding-bottom: 0;
                max-width: 1000px;
            }

            .monthList {
                display: flex;
                flex-direction: column;
                position: relative;
                width: -webkit-fill-available;
                padding: 5vh;
                padding-top: 0;
                max-width: 1000px;

                .controls {
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    width: calc(100% - 10vh);

                    button {
                        background: none;
                        color: $textColor;
                        padding: 20px;

                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }

                .scrollList {
                    display: flex;
                    margin-top: 15px;
                    gap: 5vw;

                    @include mobile {
                        flex-direction: column;
                        align-items: center;
                    }

                    .month {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        @include mobile {
                            width: 100%;
                        }

                        span {
                            text-align: center;
                            margin-bottom: 50px;
                            font-weight: bold;
                            font-size: 25px;
                        }

                        table {

                            thead {

                                th {
                                    font-size: 15px;
                                    color: $bgAccent;
                                    width: 30px;
                                    height: 20px;
                                    text-align: center;
                                }
                            }

                            tbody {

                                td {
                                    font-size: 20px;
                                    width: 30px;
                                    height: 30px;
                                    border: 1px solid transparent;
                                    text-align: center;
                                    cursor: pointer;

                                    &:hover {
                                        border: 1px solid $accent;
                                    }
                                }

                                .selected {
                                    background: $accent;
                                    color: $bg;
                                }

                                .disabled {
                                    user-select: none;
                                    color: $textColorHover;
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }
                }

                .error {
                    color: $accent;
                }
            }
        }
    }

    .form {
        padding-top: 5vh;

        .container {
            max-width: 1000px;
            gap: 10px;

            .row {
                display: flex;
                gap: 20px;

                @include tablet {
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .form-group {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 12px;

                label {
                    margin-bottom: 6px;

                    span {
                        color: $accent;
                        margin-left: 5px;
                    }
                }

                input {
                    padding: 12px 24px;
                    border: 1px $textColor solid;
                    border-bottom: 4px $accent solid;
                }

                .error {
                    margin-top: 10px;
                    color: $accent;
                }
            }
        }
    }
}