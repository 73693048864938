@import "../../style.scss";

.home {

    .header-start {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100vw;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;

        .container {
            display: flex;
            justify-content: center;
            height: 50vh;
            margin-top: 30vh;
            color: $textColor;
            z-index: 10;

            h1 {
                max-width: 600px;
                margin-bottom: 20px;
                color: $bg;
            }

            .bottomLeft {
                display: flex;
                max-width: 90%;
                bottom: 10%;
                gap: 20px;
                left: 5%;
                z-index: 1;

                a {
                    text-decoration: none;
                }
            }
        }

        .right {

            .arrow {
                display: inline-flex;
                position: absolute;
                justify-content: center;
                bottom: 20vh;
                right: 5%;
                width: 35px;
                transform: rotate(180deg);
                z-index: 10;

                .line {
                    display: inline-flex;
                    position: absolute;
                    top: 18px;
                    background: $bg;
                    width: 2px;
                    height: 150px;
                }

                .pointerL {
                    position: relative;
                    background: $bg;
                    width: 2px;
                    height: 15px;
                    transform-origin: right bottom;
                    transform: rotate(-135deg);
                }

                .pointerR {
                    position: relative;
                    background: $bg;
                    width: 2px;
                    height: 15px;
                    transform-origin: left bottom;
                    transform: rotate(135deg);
                }
            }
        }

        .arrows {
            display: flex;
            position: absolute;
            right: 5%;
            bottom: 5vh;
            gap: 20px;
            z-index: 10;
        }

        .slideshow {
            position: absolute;
            width: 100vw;
            height: inherit;
            background: $textColor;

            .slick-slider {
                height: inherit;

                .slick-list {
                    height: inherit;
                    opacity: 0.75;
                }

                .slick-track {
                    height: inherit;

                    div {
                        height: inherit;
                    }

                    img,
                    video {
                        width: 100vw;
                        height: inherit;
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                        z-index: 0;
                    }
                }

                .arrow {
                    display: flex;
                    position: absolute;
                    bottom: 5vh;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    border: 1px solid $bg;
                    transition: 0.2s ease-in-out;
                    z-index: 10;
                }

                .arrow.next {
                    right: 5%;
                    background: $accent;
                    border-color: $accent;
                    color: $bg;

                    &:hover {
                        background: $bg;
                        border-color: $bg;
                        color: $accent;
                    }
                }

                .arrow.prev {
                    right: calc(5% + 70px);
                    color: $bg;

                    &:hover {
                        background: $bg;
                        color: $accent;
                    }
                }
            }
        }
    }

    .about {
        margin-top: 20vh;

        .container {
            align-items: center;

            h3 {
                margin-bottom: 20px;
            }

            p {
                text-align: center;
            }

            a {
                padding: 20px;
            }
        }
    }

    .room {
        margin-top: 20vh;

        .modal {
            display: none;
            position: fixed;
            top: 0;
            background: $bg;
            height: 100vh;
            width: 100vw;
            z-index: 100;
        }

        .modal.active {
            display: flex;
        }

        .container {
            background: $bgBox;
            align-items: center;

            .house {
                padding: 5vh;
                width: -webkit-fill-available;
                max-width: 1000px;

                h1 {
                    margin-bottom: 5vh;
                }

                p {
                    margin-top: 5vh;
                }

                .services {
                    display: flex;
                    flex-direction: column;
                    margin-top: 5vh;
                    gap: 20px;

                    .list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;

                        .item {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            span {
                                white-space: nowrap;
                                text-wrap: wrap;
                            }
                        }
                    }
                }

                .info {
                    margin-top: 5vh;
                }
            }
        }
    }

    .vacation {
        margin-top: 20vh;

        .container {

            h1 {
                margin-bottom: 5vh;
            }

            .content {
                display: flex;
                gap: 10vh;

                @include tablet {
                    flex-direction: column;
                }

                .left {
                    width: 400px;
                    max-width: 90vw;

                    p {
                        margin-bottom: 20px;
                    }
                }

                .right {
                    display: flex;
                    flex-wrap: wrap;
                    width: 50vw;
                    min-width: 300px;
                    max-width: 90vw;
                    position: relative;
                    gap: 5vh;

                    .item {
                        display: flex;
                        flex-direction: column;
                        width: 400px;
                        max-width: 90vw;
                        gap: 5vh;

                        img {
                            width: auto;
                            transition: 0.2s ease-in-out;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}