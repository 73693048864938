.wp-content {
    max-width: 100%;
    gap: 10px;

    .is-layout-flow {
        gap: 10px;
    }

    .wp-block-columns {
        display: flex;
        gap: 50px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .icon-container {
        width: fit-content !important;

        svg {
            fill: $accent;
        }
    }
}