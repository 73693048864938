@import "../../style.scss";

.gallery {

    .slick-slider {
        max-height: 80vh;

        .slick-list {

            img {
                width: 100%;
            }
        }

        .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            height: 100%;
            width: 50px;
            z-index: 10;
            background: transparent;
            transition: 0.2s ease-in-out;
            cursor: pointer;

            svg {
                fill: $bg;
                transition: 0.2s ease-in-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        .arrow.prev {
            left: 0;

            svg:active {
                transform: translateX(-10px);
            }
        }

        .arrow.next {
            right: 0;

            svg:active {
                transform: translateX(10px);
            }
        }
    }
}