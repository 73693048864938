@import "../../style.scss";

.entdecken {

    .container {
        display: flex;
        flex-direction: column;
    }

    .beschreibung {
        margin-bottom: 10vh;

        .container {
            align-items: center;

            h3 {
                margin-bottom: 20px;
            }

            p {
                text-align: center;
            }

            a {
                padding: 20px;
            }
        }
    }

    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 50px;

        .item {
            display: flex;
            flex-direction: column;
            background: $bgBox;
            padding: 30px;
            width: calc(33% - 70px);
            max-width: 600px;
            cursor: pointer;

            @include desktop {
                width: calc(50% - 70px);
            }

            @include mobile {
                width: 100%;
            }

            img {
                width: 100%;
                max-height: 90vh;
                margin-bottom: 40px;
                transition: 0.5s ease-in-out;
            }

            h2 {
                margin-bottom: 10px;
            }

            &:hover {

                img {
                    transform: translateY(-10px);
                }
            }
        }
    }
}